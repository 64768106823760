#drawingPanel {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#pixels {
	margin-bottom: 2rem;
}

#cursor {
	position: absolute;
	top: 0;
	left: 0;
	width: 1rem;
	height: 1rem;
	z-index: 1;
	color: transparent;
}
